.about-container{
    margin: auto;
    width: 60%;
    padding: 10px;    
    text-align: center;
}

.about-us-col{
    border: 3px solid white;
    text-align: center;
}

.about-header{
    margin-bottom: 1em;
    color: #28a745;
}

.company-description{
    background-color: #28a745;
    height: auto;
    padding: 3%;
    margin-bottom: 10%;
    margin-top: 10%;
}

.aboutus-description{
    color: white;
}


@media only screen and (min-device-width: 280px) and (max-device-width: 319px) and (orientation:portrait) {
    .about-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 1.4rem;
    }

    .about-us-col {
        padding: 20px;
    }

    .about-us-col > h3 {
        font-size: 1.2rem;
    }

    .company-description {
        padding: 10%;
        border-radius: 10px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 359px) and (orientation:portrait) {
    .about-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 1.4rem;
    }

    .about-us-col {
        padding: 20px;
    }

    .about-us-col > h3 {
        font-size: 1.2rem;
    }

    .company-description {
        padding: 10%;
        border-radius: 10px;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 399px) and (orientation:portrait) {
    .about-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 1.4rem;
    }

    .about-us-col {
        padding: 20px;
    }

    .about-us-col > h3 {
        font-size: 1.2rem;
    }

    .company-description {
        padding: 10%;
        border-radius: 10px;
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 699px) and (orientation:portrait) {
    .about-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 1.4rem;
    }

    .about-us-col {
        padding: 20px;
    }

    .about-us-col > h3 {
        font-size: 1.2rem;
    }

    .company-description {
        padding: 10%;
        border-radius: 10px;
    }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 1023px) and (orientation:portrait) {
    .about-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 1.4rem;
    }

    .about-us-col {
        padding: 20px;
    }

    .about-us-col > h3 {
        font-size: 1.2rem;
    }

    .company-description {
        padding: 10%;
        border-radius: 10px;
    }
}

@media only screen and (min-device-width: 1024px) and (orientation:portrait) {
    .about-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 2rem;
    }

    .about-us-col {
        padding: 20px;
    }

    .about-us-col > h3 {
        font-size: 1.5rem;
    }

    .company-description {
        padding: 10%;
        border-radius: 10px;
    }
}


/* LANDSCAPE */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation:landscape) {
    .about-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 2rem;
    }

    .about-us-col {
        padding: 20px;
    }

    .about-us-col > h3 {
        font-size: 1.5rem;
    }

    .company-description {
        padding: 10%;
        border-radius: 10px;
    }
}

@media only screen and (min-device-width: 569px) and (max-device-width: 823px) and (orientation:landscape) {
    .about-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 2rem;
    }

    .about-us-col {
        padding: 20px;
    }

    .about-us-col > h3 {
        font-size: 1.5rem;
    }

    .company-description {
        padding: 10%;
        border-radius: 10px;
    }
}

@media only screen and (max-device-width: 1366px) and (orientation:landscape) {
    .about-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 2rem;
    }

    .about-us-col {
        padding: 20px;
    }

    .about-us-col > h3 {
        font-size: 1.5rem;
    }

    .company-description {
        padding: 10%;
        border-radius: 10px;
    }
}

