.contact-body{
    margin: auto;
    width: 50%;
    padding: 10px;    
    text-align: center;
}

.contact-header{
    margin-bottom: 1em;
    color: #28a745;
}

.whatsappIcon{
    margin-top: 25px;
    width: 15%;
}

.whatsappIcon:hover{
    cursor: pointer;
}


@media only screen and (min-device-width: 280px) and (max-device-width: 319px) and (orientation:portrait) {
    .contact-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 1.4rem;
    }

    .whatsappIcon {
        margin-top: 50px;
        width: 45%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 359px) and (orientation:portrait) {
    .contact-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 1.4rem;
    }
    .whatsappIcon {
        margin-top: 50px;
        width: 45%;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 399px) and (orientation:portrait) {
    .contact-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 1.4rem;
    }

    .whatsappIcon {
        margin-top: 50px;
        width: 45%;
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 699px) and (orientation:portrait) {
    .contact-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 1.4rem;
    }

    .whatsappIcon {
        margin-top: 50px;
        width: 40%;
    }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 1023px) and (orientation:portrait) {
    .contact-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 1.4rem;
    }
    .whatsappIcon {
        margin-top: 50px;
        width: 30%;
    }
}

@media only screen and (min-device-width: 1024px) and (orientation:portrait) {
    .contact-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 2rem;
    }
    .whatsappIcon {
        margin-top: 50px;
        width: 35%;
    }
}


/* LANDSCAPE */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation:landscape) {
    .contact-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 2rem;
    }
}

@media only screen and (min-device-width: 569px) and (max-device-width: 823px) and (orientation:landscape) {
    .contact-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 2rem;
    }
}

@media only screen and (max-device-width: 1366px) and (orientation:landscape) { 
    .contact-header{
        margin-bottom: 1em;
        color: #28a745;
        font-size: 2rem;
    }
 }