footer {
    position: relative;
    bottom: 0;
    padding: 1rem;
    width: 100%;
    background-color:#28a745;
    text-align: center;
    color: aliceblue;
    margin-top: 10em
  }

  .footer-row{
    background-color: #28a745 !important;
  }

.footer-col {
  background-color: #28a745 !important;
}

.image-footer {
  width: 210px;
}


.social-media > i {
  margin-right: 20px;
}

.social-media {
  color: white;
}


  @media only screen and (min-device-width: 280px) and (max-device-width: 319px) and (orientation:portrait) {
    footer {
      position: relative;
      bottom: 0;
      padding: 1rem;
      width: auto;
      background-color: #28a745;
      text-align: center;
      color: aliceblue;
      margin-top: 10em;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 359px) and (orientation:portrait) {
  footer {
    position: relative;
    bottom: 0;
    padding: 1rem;
    width: auto;
    background-color: #28a745;
    text-align: center;
    color: aliceblue;
    margin-top: 10em;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 399px) and (orientation:portrait) {
  footer {
    position: relative;
    bottom: 0;
    padding: 1rem;
    width: auto;
    background-color: #28a745;
    text-align: center;
    color: aliceblue;
    margin-top: 10em;
  }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 699px) and (orientation:portrait) {
  footer {
    position: relative;
    bottom: 0;
    padding: 1rem;
    width: auto;
    background-color: #28a745;
    text-align: center;
    color: aliceblue;
    margin-top: 10em;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 1023px) and (orientation:portrait) {
  footer {
    position: relative;
    bottom: 0;
    padding: 1rem;
    width: auto;
    background-color: #28a745;
    text-align: center;
    color: aliceblue;
    margin-top: 10em;
  }
}

@media only screen and (min-device-width: 1024px) and (orientation:portrait) {
  footer {
    position: relative;
    bottom: 0;
    padding: 1rem;
    width: auto;
    background-color: #28a745;
    text-align: center;
    color: aliceblue;
    margin-top: 10em;
  }
}

