
    
  .long {
    width: 100%;
    background-color: #010e19;
  }
 
    
  .scroll {
    opacity: 0.9;
    background-color: lightgreen;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 5px;
    border: none;
    display: none;
  }

  .scroll:hover {
    background-color: green;
    
  }
  
  .arrow-up {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -5px;
  }
    


@media only screen and (max-device-width: 480px) {
  .scroll {
    opacity: 0.9;
    background-color: lightgreen;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    right: 39px;
    border-radius: 5px;
    border: none;
    display: none;
    margin-right: -7%;
  }

}

@media only screen and (max-device-width: 320) {
  .scroll {
    opacity: 0.9;
    background-color: lightgreen;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    right: 76px;
    border-radius: 5px;
    border: none;
    display: none;
  }

}