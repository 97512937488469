.slogan{
    display: inline-flex;
    margin-left: 2em;
    margin-top: 1em;
}

.navbar {
    background-color: white !important;
}


@media only screen and (min-device-width: 280px) and (max-device-width: 319px) and (orientation:portrait) {
    .slogan{
        display: none;
    }

    .fixed-top {
        position: relative;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 359px) and (orientation:portrait) {
    .slogan{
        display: none;
    }

    .fixed-top {
        position: relative;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 399px) and (orientation:portrait) {
    .slogan{
        display: none;
    }

    .fixed-top {
        position: relative;
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 699px) and (orientation:portrait) {

    .slogan{
        display: none;
    }

    .fixed-top {
        position: relative;
    }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 1023px) and (orientation:portrait) {
    .slogan {
        font-size: 21px;
    }

    .fixed-top {
        position: relative;
    }
}

@media only screen and (min-device-width: 1024px) and (orientation:portrait) {
    .slogan {
        font-size: 18px;
    }

    .fixed-top {
        position: relative;
    }
}


/* LANDSCAPE */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation:landscape) {
    .slogan {
        font-size: 18px;
    }

    .fixed-top {
        position: relative;
    }
}

@media only screen and (min-device-width: 569px) and (max-device-width: 823px) and (orientation:landscape) {
    .slogan {
        font-size: 21px;
    }

    .fixed-top {
        position: relative;
    }
}
