#ind {
    margin-bottom: 3%;
}

#indImage >img {
    height: 307px;
}
.left-position > .products-container > .category-image > img{
    height: 232px;
    width: 430px;
    grid-area: categoryImage;
    /* margin-left: 20%; */
}

.left-position > .products-container > .product-list {
    columns: 2;
    position:relative;
    left:3%;
    text-align: left;
    width: 960px;
    grid-area: productList;
    margin-top: 4%;
}

.left-position > .products-container > .product-list > .category-tittle {
    margin-bottom: 5%;
}


.left-position > .products-container {
    display: grid;
    grid-template-areas: 'productList categoryImage';
    background-color: #eeeeee;
}

.right-position > .products-container > .category-image > img{
    height: 232px;
    width: 430px;
    grid-area: categoryImage;
    /* margin-right: 20%; */
}

.right-position > .products-container > .product-list {
    columns: 2;
    position:relative;
    left:3%;
    text-align: left;
    width: 960px;
    grid-area: productList;
    margin-top: 4%;
}

.right-position > .products-container > .product-list > .category-tittle {
    margin-bottom: 5%;
}


.right-position > .products-container {
    display: grid;
    grid-template-areas: 'categoryImage productList';
    background-color: white;
}

.right-position >  .hidden-category {
    display: none;
}

.left-position >  .hidden-category {
    display: none;
}

.products-section {
    margin-left: 10%;
    margin-right: 10%;
}

.right-position , .left-position {
    margin-top: 5%;
}

.show-button {
    margin-bottom: 5%;
}

.hide-button {
    margin-top: 5%;
    margin-bottom: 5%;
}

.products-header {
    color: #28a745;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 319px) and (orientation:portrait) {
    /* .products-section {
        background-color: green;
    } */
    #indImage >img {
        height: 200px;
    }

    .products-header {
        font-size: 1.5rem;
    }

    .category-tittle {
        font-size: 1rem;
    }

    .product-list > li{
        font-size: 0.8rem;
    }

    /* LEFT CONTROLS */

    .left-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .left-position > .products-container > .category-image > img {
        height: 200px;
        width: 230px;
    }

    .left-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    /* RIGHT CONTROLS */

    .right-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .right-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    .right-position > .products-container > .category-image > img {
        height: 200px;
        width: 230px;
    }

    .right-position >  .hidden-category {
        display: none;
    }
    
    .left-position >  .hidden-category {
        display: none;
    }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 359px) and (orientation:portrait) {
    /* .products-section {
        background-color: lightcoral;
    } */
    #indImage >img {
        height: 225px;
    }

    .products-header {
        font-size: 1.5rem;
    }

    .category-tittle {
        font-size: 1rem;
    }

    .product-list > li{
        font-size: 0.8rem;
    }

    /* LEFT CONTROLS */

    .left-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .left-position > .products-container > .category-image > img {
        height: 225px;
        width: 250px;
    }

    .left-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    /* RIGHT CONTROLS */

    .right-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .right-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    .right-position > .products-container > .category-image > img {
        height: 225px;
        width: 250px;
    }

    .right-position >  .hidden-category {
        display: none;
    }
    
    .left-position >  .hidden-category {
        display: none;
    }

}

@media only screen and (min-device-width: 360px) and (max-device-width: 399px) and (orientation:portrait) {
    /* .products-section {
        background-color: red;
    } */

    #indImage >img {
        height: 225px;
    }

    .products-header {
        font-size: 1.5rem;
    }

    .category-tittle {
        font-size: 1rem;
    }

    .product-list > li{
        font-size: 0.8rem;
    }

    /* LEFT CONTROLS */

    .left-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .left-position > .products-container > .category-image > img {
        height: 225px;
        width: 289px;
    }

    .left-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    /* RIGHT CONTROLS */

    .right-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .right-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    .right-position > .products-container > .category-image > img {
        height: 225px;
        width: 289px;
    }

    .right-position >  .hidden-category {
        display: none;
    }
    
    .left-position >  .hidden-category {
        display: none;
    }

}

@media only screen and (min-device-width: 400px) and (max-device-width: 699px) and (orientation:portrait) {
    /* .products-section {
        background-color: blue;
    } */

    #indImage >img {
        height: 250px;
    }

    .products-header {
        font-size: 1.5rem;
    }

    .category-tittle {
        font-size: 1rem;
    }

    .product-list > li{
        font-size: 0.8rem;
    }

    /* LEFT CONTROLS */

    .left-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .left-position > .products-container > .category-image > img {
        height: 250px;
        width: 300px;
    }

    .left-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    /* RIGHT CONTROLS */

    .right-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .right-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    .right-position > .products-container > .category-image > img {
        height: 250px;
        width: 300px;
    }

    .right-position >  .hidden-category {
        display: none;
    }
    
    .left-position >  .hidden-category {
        display: none;
    }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 1023px) and (orientation:portrait) {
    /* .products-section {
        background-color: greenyellow;
    } */

    #indImage >img {
        height: 300px;
    }

    .products-header {
        font-size: 1.5rem;
    }

    .category-tittle {
        font-size: 1rem;
    }

    .product-list > li{
        font-size: 0.8rem;
    }

    /* LEFT CONTROLS */

    .left-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .left-position > .products-container > .category-image > img {
        height: 300px;
        width: 450px;
    }

    .left-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    /* RIGHT CONTROLS */

    .right-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .right-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    .right-position > .products-container > .category-image > img {
        height: 300px;
        width: 450px;
    }

    .right-position >  .hidden-category {
        display: none;
    }
    
    .left-position >  .hidden-category {
        display: none;
    }
}

@media only screen and (min-device-width: 1024px) and (orientation:portrait) {
    /* .products-section {
        background-color: gray;
    } */

    #indImage >img {
        height: 350px;
    }

    .products-header {
        font-size: 2rem;
    }

    .category-tittle {
        font-size: 1.5rem;
    }

    .product-list > li{
        font-size: 1.3rem;
    }

    /* LEFT CONTROLS */

    .left-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .left-position > .products-container > .category-image > img {
        height: 350px;
        width: 500px;
    }

    .left-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    /* RIGHT CONTROLS */

    .right-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .right-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    .right-position > .products-container > .category-image > img {
        height: 350px;
        width: 500px;
    }

    .right-position >  .hidden-category {
        display: none;
    }
    
    .left-position >  .hidden-category {
        display: none;
    }
}

/* LANDSCAPE */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (orientation:landscape) {
    /* .products-section {
        background-color: blue;
    } */
    #indImage >img {
        height: 250px;
    }

    .products-header {
        font-size: 1.5rem;
    }

    .category-tittle {
        font-size: 1rem;
    }

    .product-list > li{
        font-size: 0.8rem;
    }

    /* LEFT CONTROLS */

    .left-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .left-position > .products-container > .category-image > img {
        height: 250px;
        width: 300px;
    }

    .left-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    /* RIGHT CONTROLS */

    .right-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .right-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    .right-position > .products-container > .category-image > img {
        height: 250px;
        width: 300px;
    }

    .right-position >  .hidden-category {
        display: none;
    }
    
    .left-position >  .hidden-category {
        display: none;
    }
}

@media only screen and (min-device-width: 569px) and (max-device-width: 823px) and (orientation:landscape) {
    /* .products-section {
        background-color: red;
    } */
    #indImage >img {
        height: 250px;
    }

    .products-header {
        font-size: 1.5rem;
    }

    .category-tittle {
        font-size: 1rem;
    }

    .product-list > li{
        font-size: 0.8rem;
    }

    /* LEFT CONTROLS */

    .left-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .left-position > .products-container > .category-image > img {
        height: 250px;
        width: 300px;
    }

    .left-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    /* RIGHT CONTROLS */

    .right-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .right-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    .right-position > .products-container > .category-image > img {
        height: 250px;
        width: 300px;
    }

    .right-position >  .hidden-category {
        display: none;
    }
    
    .left-position >  .hidden-category {
        display: none;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation:landscape) { 
    /* .products-section {
        background-color: green;
    } */

    #indImage >img {
        height: 400px;
    }

    .products-header {
        font-size: 2.5rem;
    }

    .category-tittle {
        font-size: 2rem;
    }

    .product-list > li{
        font-size: 1rem;
    }

    /* LEFT CONTROLS */

    .left-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .left-position > .products-container > .category-image > img {
        height: 400px;
        width: 500px;
    }

    .left-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    /* RIGHT CONTROLS */

    .right-position > .products-container {
        background-color: white;
        margin-bottom: 20%;
        display: block;
    }

    .right-position > .products-container > .product-list {
        columns: 1;
        position: relative;
        width: auto;
    }

    .right-position > .products-container > .category-image > img {
        height: 400px;
        width: 500px;
    }

    .right-position >  .hidden-category {
        display: none;
    }
    
    .left-position >  .hidden-category {
        display: none;
    }
 }